<script>
    import Footer from '@/template/footer.svelte';
    import Header from '@/template/header.svelte';
    export let authUser;
    export let Cookie;
    export let page_description;
    export let share_url;
    export let share_title;
    export let share_description;
    export let share_type;
    export let share_img;
</script>

<svelte:head>
    <title>{share_title}</title>
    <meta name="description" content="{page_description}" />
    <meta property="og:url" content="{share_url}" />
    <meta property="og:title" content="{share_title}" />
    <meta property="og:description" content="{share_description}" />
    <meta property="og:type" content="{share_type}" />
    <meta property="og:image" content="{share_img}" />
</svelte:head>

<Header {authUser} />

<main class="mx-auto max-w-7xl px-4 py-8 sm:px-6 lg:px-8">
    <div class=" text-black dark:text-white">
        <div class="transition-colors duration-300">
            <h1 class="mb-6 text-4xl font-bold">Contact Us</h1>

            <div class="mb-4">
                <h2 class="mb-4 text-2xl font-bold dark:text-white">Support Portal</h2>
                <a
                    href="https://support.buymynudes.com"
                    class="text-decoration-none flex w-36 items-center justify-center gap-x-2 rounded-md bg-black px-6 py-2 text-white hover:bg-opacity-90 dark:bg-white dark:text-black"
                    >Open
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="inline-block size-6"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                        ></path>
                    </svg>
                </a>
            </div>

            <div class="mb-4">
                <h2 class="mb-4 text-2xl font-bold dark:text-white">Billing Issues</h2>
                <p class="dark:text-gray-300">
                    If you'd like to cancel your subscription and not to be billed again, or for any billing questions
                    including refunds on a case-by-case basis, please contact our billing partner, CCBill via phone
                    1.888.596.9279, or email at <a
                        href="mailto:consumersupport@ccbill.com"
                        class="text-indigo-500 hover:underline dark:text-indigo-400">consumersupport@ccbill.com</a
                    >.
                </p>
            </div>

            <div class="mb-4">
                <h2 class="mb-4 text-2xl font-bold dark:text-white">Complaints</h2>
                <p class="dark:text-gray-300">
                    For all complaints, please contact us through <a
                        href="mailto:support@buymynudes.com"
                        class="text-indigo-500 hover:underline dark:text-indigo-400">support@buymynudes.com</a
                    >. Complaints will be reviewed and resolved within seven (7) business days. That resolution will
                    then be communicated to the Complainant. Appeals or requests for any decision made should be
                    presented, again, to
                    <a href="mailto:support@buymynudes.com" class="text-indigo-500 hover:underline dark:text-indigo-400"
                        >support@buymynudes.com</a
                    >.
                </p>
            </div>

            <div class="mb-4">
                <h2 class="mb-4 text-2xl font-bold dark:text-white">Appeals</h2>
                <p class="dark:text-gray-300">
                    If you have been depicted in any content and would like to appeal removal of such content, please
                    notify us by email at <a
                        href="mailto:support@buymynudes.com"
                        class="text-indigo-500 hover:underline dark:text-indigo-400">support@buymynudes.com</a
                    >. If there should be disagreement regarding an appeal, we will allow the disagreement to be
                    resolved by a neutral party.
                </p>
            </div>

            <div class="mb-4 flex flex-col">
                <h2 class="mb-4 text-2xl font-bold dark:text-white">Company Information</h2>
                <span><span>BuyMyNudes</span>, maintained by:</span>
                <div class="*-gap-x-2 flex flex-col text-sm">
                    <span class="dark:text-gray-300">Bilin GmbH</span>
                    <span class="dark:text-gray-300"><strong>City:</strong> Markkleeberg</span>
                    <span class="dark:text-gray-300"><strong>State/Province:</strong> Saxony</span>
                    <span class="dark:text-gray-300"><strong>Country:</strong> Germany</span>
                    <span class="dark:text-gray-300"
                        ><strong>Contact Email:</strong>
                        <a
                            href="mailto:corporate-contact@buymynudes.com"
                            class="text-indigo-500 hover:underline dark:text-indigo-400"
                            >corporate-contact@buymynudes.com</a
                        ></span
                    >
                </div>
            </div>
        </div>
    </div>
</main>

<Footer />
